import { useState, useEffect } from "react";
import Axios from "axios";
import Puzzle from "./Puzzle";
import { fadeInOut, dropIn } from "utils/animations";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utils/useWindowDimensions";
import {
  showFailReducer,
  completedGameReducer,
} from "../../../../store/gameSlice";
import CountDown from "components/CountDown/CountDown";
import Confetti from "react-confetti";
import { CountDownPreGame } from "components/CountDown/CountDownPreGame";

import Button from "components/Button/Button";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { motion, AnimatePresence } from "framer-motion";

import imgPuzzle from "assets/images/_jub/puzzle.jpg";

import { importImages } from "utils/importImages";
const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function PuzzleGame({ gameData }) {
  const [showScore, setShowScore] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [completeCountdown, setCompleteCoundown] = useState(false);

  const showFail = useSelector((state) => state.game.showFail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();

  const resetGame = () => {
    setShowScore(null);
    dispatch(showFailReducer(false));
  };

  const completeGame = () => {
    dispatch(completedGameReducer("puzzlegame"));
    navigate("/games");
  };

  useEffect(() => {
    if (showScore === "success") {
      setShowConfetti(true);
      dispatch(completedGameReducer("puzzlegame"));
      window.plausible("puzzlegame_complete");
    }
  }, [showScore]);

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`messages ingame d-flex justify-content-center align-items-center ${gameData.id}`}>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          colors={["#f58634", "#6ab2dc"]}
          numberOfPieces={200}
          friction={0.99}
          gravity={0.3}
          tweenDuration={500}
          recycle={false}
          opacity={0.8}
          drawShape={(ctx) => {
            function strokeStar(x, y, r, n, inset) {
              ctx.save();
              ctx.beginPath();
              ctx.translate(x, y);
              ctx.moveTo(0, 0 - r);
              for (var i = 0; i < n; i++) {
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r * inset);
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r);
              }
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            }
            strokeStar(5, 6, 3, 4, 4);
          }}
        />
      )}
      <div className="container narrow text-center d-flex align-items-center justify-content-center mt-6 mt-md-4">
        <div className="w-100">
          <h1
            className="text-white"
            dangerouslySetInnerHTML={{ __html: t("G3H") }}
          />
          <div className="question-section">
            <p
              className="question-text text-center text-white"
              dangerouslySetInnerHTML={{ __html: t("G3P") }}
            />
          </div>

          <div className="mt-3 game-container position-relative">
            <AnimatePresence>
              {!completeCountdown && (
                <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                    <motion.div
                      animate={{ opacity: 1, translateX: 0 }}
                      exit={{ opacity: 0, translateX: 100 }}>
                      <CountDownPreGame
                        completeCountdown={completeCountdown}
                        setCompleteCoundown={setCompleteCoundown}
                      />
                    </motion.div>
                    <div className="overlay" />
                  </div>
                </motion.div>
              )}

              {showScore === "success" && (
                <motion.div
                  initial={{ opacity: 0, translateX: 0 }}
                  animate={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: 0 }}
                  transition={{ delay: 3 }}
                  className="score">
                  <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                    <div>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: t(gameData.successh),
                        }}
                      />
                      <p
                        className="text-white lead"
                        dangerouslySetInnerHTML={{
                          __html: t(gameData.successp),
                        }}
                      />

                      <Button
                        className="btn btn-orange px-5 mt-3"
                        label={t("Next")}
                        onClick={() => navigate("/games")}
                      />
                    </div>
                  </div>
                  <div className="overlay" />
                </motion.div>
              )}
              {showFail && (
                <motion.div
                  initial={{ opacity: 0, translateX: 0 }}
                  animate={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: 100 }}
                  className="score">
                  <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                    <div>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: t(gameData.failh),
                        }}
                      />
                      <p
                        className="text-white lead"
                        dangerouslySetInnerHTML={{
                          __html: t(gameData.failp),
                        }}
                      />

                      <Button
                        className="btn btn-orange px-5 mt-3"
                        label={t("Playagain")}
                        onClick={() => resetGame()}
                      />
                      <div>
                        <Button
                          className="btn btn-sm px-5 mt-3 text-decoration-underline"
                          onClick={() => completeGame()}
                          label={t("EndChallenge")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="overlay" />
                </motion.div>
              )}
            </AnimatePresence>

            <div className="p-2 p-md-3 position-relative">
              <CountDown
                gameData={gameData}
                showScore={showScore}
                completeCountdown={completeCountdown}
              />

              <div className="mt-2">
                <Puzzle
                  image={imgPuzzle}
                  setShowScore={setShowScore}
                  showScore={showScore}
                  showFail={showFail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60px", left: "15%" }}
        src={images["elm-marjetica.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "20%", right: "10%" }}
        src={images["elm-zvezda.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60%", right: "5%" }}
        src={images["elm-marjetica.png"]}
      />

      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "10%", left: "5%" }}
        src={images["elm-skoljka1.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "0%", right: "15%" }}
        src={images["elm-skoljka2.png"]}
      />
    </motion.div>
  );
}

export default PuzzleGame;
