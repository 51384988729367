import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { showFailReducer } from "../../../store/gameSlice";
import { fadeInOut, staggerItems, item } from "../../../utils/animations";
import Wrapper from "../../../components/Wrapper/Wrapper";

import Button from "../../../components/Button/Button";

function Fail({ gameData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showFailReducer(true));
    return () => {
      dispatch(showFailReducer(false));
    };
  }, []);

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`messages d-flex justify-content-center align-items-center ${gameData.id}`}>
        <div className="w-100 text-center">
          <Wrapper className="wrapper__full-width">
            <div className="content ">
              <h2>Oh ne, prehitel te je čas!</h2>
              <p className="lead">
                Če želiš igro rešiti do konca, se moraš malo podvizati.{" "}
              </p>
            </div>
          </Wrapper>

          <div className="button-container">
            <div className="row">
              <div className="col-12">
                <Button
                  label="Poskusi ponovno"
                  onClick={() => dispatch(showFailReducer(false))}
                  className="btn btn-texture mt-4"
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Fail;
