import i18n from "i18n/config";

export const data = {
  quizsteps: {
    id: "quizsteps",
    headline: "Quiz Steps",
    step: [
      {
        gamePic: "HR.png",
        triviaPic: "img_trivia_map.jpg",
        triviaTxt: "Trivia besedilo1",
        question: "Navodila1...",
        questionText:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna..",
        answerOptions: [
          { answerText: i18n.t("G1Q1A1"), isCorrect: true },
          { answerText: i18n.t("G1Q1A2"), isCorrect: false },
        ],
      },
      {
        gamePic: "SLO.png",
        triviaPic: "img_trivia.jpg",
        triviaTxt: "Trivia besedilo2",
        question: "Navodila2...",
        questionText:
          "xperienced a surge in popularity during the 1960s when Letraset used it on their d.",
        answerOptions: [
          { answerText: i18n.t("G1Q2A1"), isCorrect: true },
          { answerText: i18n.t("G1Q2A2"), isCorrect: false },
        ],
      },
      {
        gamePic: "BIH.png",
        triviaPic: "img_trivia.jpg",
        triviaTxt: "Trivia besedilo3",
        question: "Navodila3...",
        questionText:
          "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in t",
        answerOptions: [
          { answerText: i18n.t("G1Q3A1"), isCorrect: true },
          { answerText: i18n.t("G1Q3A2"), isCorrect: false },
        ],
      },
      {
        gamePic: "SRB.png",
        triviaPic: "img_trivia.jpg",
        triviaTxt: "Trivia besedilo3",
        question: "Navodila3...",
        questionText:
          "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in t",
        answerOptions: [
          { answerText: i18n.t("G1Q4A1"), isCorrect: true },
          { answerText: i18n.t("G1Q4A2"), isCorrect: false },
        ],
      },
    ],
    successh: i18n.t("G1SuccessH"),
    successp: i18n.t("G1SuccessP"),
    failh: i18n.t("G1FailH"),
    failp: i18n.t("G1FailP"),

    timer: 3000,
  },
  memorygame: {
    id: "memorygame",
    headline: "Začaran spomin",
    description:
      "<h4 class='pb-2 text-blue'>Revelio!</h4> <p>Upamo, da te nista kakšna navihana čarovnica ali čarovnik uročila z urokom pozabe. Čaka te namreč igra spomina s čarobnimi figuricami in nepozabnimi elementi iz namizne igre Čarobne figurice!<br>Pobrskaj med sličicami dveh nivojev in poišči ustrezne pare. 🦉 </p>",
    success1:
      "Imaš odličen spomin! Tvoja pametna glav'ca je kos še tako zahtevnim čarobnim izzivom. Le tako naprej!",
    gameData: [
      ["memory1_a", "memory1_b", "memory2_a", "memory2_b"],
      ["memory3_a", "memory3_b", "memory4_a", "memory4_b"],
    ],
    successh: i18n.t("G2SuccessH"),
    successp: i18n.t("G2SuccessP"),
    failh: i18n.t("G2FailH"),
    failp: i18n.t("G2FailP"),
    timer: 4000,
  },
  puzzlegame: {
    id: "memorygame",
    headline: "Začaran spomin",
    description:
      "<h4 class='pb-2 text-blue'>Revelio!</h4> <p>Upamo, da te nista kakšna navihana čarovnica ali čarovnik uročila z urokom pozabe. Čaka te namreč igra spomina s čarobnimi figuricami in nepozabnimi elementi iz namizne igre Čarobne figurice!<br>Pobrskaj med sličicami dveh nivojev in poišči ustrezne pare. 🦉 </p>",
    success1:
      "Imaš odličen spomin! Tvoja pametna glav'ca je kos še tako zahtevnim čarobnim izzivom. Le tako naprej!",
    gameData: [
      ["memory1_a", "memory1_b", "memory2_a", "memory2_b"],
      ["memory3_a", "memory3_b", "memory4_a", "memory4_b"],
    ],
    successh: i18n.t("G3SuccessH"),
    successp: i18n.t("G3SuccessP"),
    failh: i18n.t("G3FailH"),
    failp: i18n.t("G3FailP"),
    timer: 4000,
  },
};
