import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { fadeInOut, staggerItems, item } from "../../utils/animations";

import Wrapper from "../../components/Wrapper/Wrapper";
import Button from "../../components/Button/Button";

import imgNagrade1 from "../../assets/images/nagrade/1nagrada.jpg";
import imgNagrade2 from "../../assets/images/nagrade/2nagrada.jpg";
import imgNagrade3 from "../../assets/images/nagrade/3nagrada.jpg";
import imgNagrade4 from "../../assets/images/nagrade/4nagrada.jpg";
import imgX from "../../assets/images/x.svg";

function Nagrade({ gameData }) {
  const navigate = useNavigate();
  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="messages ingame d-flex justify-content-center align-items-center">
      <div className="w-100 text-center nagrade">
        <Wrapper className="wrapper__narrow">
          <img onClick={() => navigate(-1)} className="close" src={imgX} />
          <div className="px-sm-5">
            <div className="row d-flex align-items-center mb-4">
              <div className="col-12 col-sm-6">
                <img src={imgNagrade1} />
              </div>
              <div className="col-12 col-sm-6">
                <h3 className="mb-2">1. nagrada</h3>
                <p className="lead">ZBRIKA HARRY POTTER KNJIG V TRDI VEZAVI</p>
                <p>
                  Še nimaš svoje zbirke Harry Potter knjig v slovenskem jeziku?
                  To je tvoja priložnost!
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-sm-6">
                <img src={imgNagrade2} />
                <h2 className="mb-2">2. nagrada</h2>

                <p className="lead">SET HARRY POTTER LEGO KOCK</p>
                <p>LEGO® HARRY POTTER™ – BRADAVIČARKA: DVORANA SKRIVNOSTI</p>
                <p className="small">
                  Podaj se na LEGO dogodivščino in odkrij vse skrivnosti
                  Bradavičarke.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <img src={imgNagrade3} />
                <h2 className="mb-2">3. nagrada</h2>
                <p className="lead">SET HARRY POTTER LEGO KOCK</p>
                <p>LEGO® HARRY POTTER™ – ZVONIK NA BRADAVIČARKI</p>
                <p className="small">
                  Za neskončne ure zabave z junaki Wizarding World na čarobni
                  Bradavičarki.
                </p>
              </div>
            </div>

            <div className="row d-flex align-items-center mb-4">
              <div className="col-12 col-sm-6">
                <img src={imgNagrade4} />
              </div>
              <div className="col-12 col-sm-6">
                <h2 className="mb-2">4.–10. nagrada</h2>
                <p className="lead">
                  HARRY POTTER POSTELJNINA <br />
                  <span className="fw-normal">
                    + LIDL VREDNOSTNA KARTICA ZA 20 EUR
                  </span>
                </p>
                <p>
                  Naj te tvoji najljubši čarobni junaki spremljajo tudi doma!
                </p>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </motion.div>
  );
}

export default Nagrade;
