import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFailReducer } from "../../store/gameSlice";
import { ReactComponent as ImgClock } from "../../assets/images/icon_clock.svg";
import useSound from "use-sound";
import soundLost from "../../assets/sounds/Error.wav";

function CountDown({ errors, gameData, showScore, completeCountdown }) {
  const [timerClock, setTimerClock] = useState(gameData.timer);

  const dispatch = useDispatch();
  const isPlaying = useSelector((state) => state.game.isPlaying);
  const showFail = useSelector((state) => state.game.showFail);

  const [playLost] = useSound(soundLost, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  useEffect(() => {
    const timer = setTimeout(function () {
      if (timerClock > 0 && showScore !== "success" && completeCountdown) {
        setTimerClock(timerClock - 1);
      }
    }, 10);

    if (timerClock <= 0) {
      dispatch(showFailReducer(true));
      playLost();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [timerClock, completeCountdown]);

  /*   useEffect(() => {
    if (errors > 0) {
      setTimeout(() => {
        setTimerClock(timerClock - 100);
      }, 400);
    }
  }, [errors]); */

  useEffect(() => {
    if (!showFail) {
      setTimerClock(gameData.timer);
    }
  }, [showFail]);

  return (
    <>
      <div
        className={`game-status ${gameData.id} px-2 d-flex align-items-center text-start`}>
        <div className="flex-grow-1 pe-3">
          <div className="round-time-bar">
            <div
              style={{
                width: (timerClock * 100) / gameData.timer + "%",
              }}
              className={timerClock < 500 ? "red" : null}></div>
          </div>

          <div className="d-none">
            <span onClick={() => setTimerClock(timerClock - 10)}>MINUS</span>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <span className="ms-1 text-white fw-bold">
            {(timerClock / 100).toFixed(0)}
          </span>
        </div>
      </div>
    </>
  );
}

export default CountDown;
