import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { importImages } from "utils/importImages";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../../../utils/animations";

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function Card(props, index) {
  const { onClick, selected, value, flipping, matched } = props;

  function click(e) {
    if (!selected && !matched && !flipping) {
      onClick();
    }
  }

  const classes = classNames({
    card: true,
    selected,
    flipping,
    matched,
  });

  return (
    <div onClick={click} className={classes}>
      <div className="back"></div>
      <div className="front">
        <p>
          <img alt="frontimg" src={images[`${value}.png`]} />
        </p>
      </div>
    </div>
  );
}

Card.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  flipping: PropTypes.bool,
  matched: PropTypes.bool,
  value: PropTypes.string,
};

export default Card;
