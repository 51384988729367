import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import gameSlice, { showIntroReducer } from "../../../store/gameSlice";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../../utils/animations";
import Wrapper from "../../../components/Wrapper/Wrapper";

import Button from "../../../components/Button/Button";

import { ReactComponent as ImgStarLeft } from "../../../assets/images/stars_left.svg";
import { ReactComponent as ImgStarRight } from "../../../assets/images/stars_right.svg";
import imgElementMemoryGame from "../../../assets/images/bg_element_zacaran_spomin.png";
import imgElementCarobniMagneti from "../../../assets/images/bg_element_carobnimagenti.png";
import imgElementSkrivnostneRazlike from "../../../assets/images/bg_element_skrivnostnerazlike.png";

function Intro({ gameData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(showSuccessReducer(false));
    dispatch(showIntroReducer(true));
  }, []);
  /* 
  const bgImage = () => {
    if (gameData.id === "memorygame") {
      return imgElementMemoryGame;
    }

    if (gameData.id === "carobnimagneti") {
      return imgElementMemoryGame;
    }

    if (gameData.id === "skrivnostnerazlike") {
      return imgElementMemoryGame;
    }
  }; */

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`messages d-flex justify-content-center align-items-center ${gameData.id}`}>
        <img
          className={`bg-element ${gameData.id}`}
          src={
            gameData.id === "memorygame"
              ? imgElementMemoryGame
              : gameData.id === "carobnimagneti"
              ? imgElementCarobniMagneti
              : imgElementSkrivnostneRazlike
          }
        />
        <div className="w-100 text-center">
          <div className="headline-star-container">
            {" "}
            <h1
              className="text-texture heading-star mb-4"
              style={{ lineHeight: "1.2" }}>
              <ImgStarLeft className="star star-left" />
              {gameData.headline} <ImgStarLeft className="star star-right" />
            </h1>
          </div>

          <Wrapper className="wrapper__full-width">
            <div className="content ">
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: gameData.description }}
              />
            </div>
          </Wrapper>

          <div className="button-container">
            <div className="row">
              <div className="col-12">
                <Button
                  label="Naprej"
                  onClick={() => dispatch(showIntroReducer(false))}
                  className="btn btn-texture mt-sm-4"
                />
                <Link to="/games">
                  <Button
                    label="Prikaži druge igre"
                    className="btn btn-sm btn-texture py-1 mt-2"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Intro;
