import React from "react";
import PropTypes from "prop-types";
import Cell from "./Cell";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";

import Confetti from "react-dom-confetti";
import withRouter from "../../../../utils/withRouter.js";

import winSound from "assets/sounds/Correct.wav";
import moveSound from "../../../../assets/sounds/bite.mp3";

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 70,
  elementCount: 70,
  dragFriction: 0.1,
  duration: 2000,
  stagger: 1,
  width: "15px",
  height: "15px",
  perspective: "500px",
  colors: ["#E41E20", "#FF9B55", "#612415", "#EC5222"],
};

/**
 * Shuffles the passed array and returns a new one
 *
 * @param  {Array} a
 * @return {Array}
 */
function shuffle(a) {
  const b = a.slice();

  for (let i = b.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [b[i], b[j]] = [b[j], b[i]];
  }

  return b;
}

class Puzzle extends React.Component {
  constructor(props) {
    super(props);

    const { level, showFail } = props;
    const cells = level * level;

    this.state = {
      positions: [...Array(cells).keys()],
      showConfeti: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
  }

  componentDidMount() {
    const { positions } = this.state;

    this.setState({ positions: shuffle(positions) });

    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
  }

  componentWillReceiveProps = () => {
    const { positions } = this.state;

    if (this.props.showFail) {
      this.setState({ positions: shuffle(positions) });
    }
  };

  onSwap(sourcePosition, dropPosition) {
    let audioMove = new Audio(moveSound);
    let audioWin = new Audio(winSound);

    //audioMove.play();

    const oldPositions = this.state.positions.slice();
    const newPositions = [];
    let done = true;
    let p = 0;

    for (let i in oldPositions) {
      let value = oldPositions[i];
      let newValue = value;

      if (value === sourcePosition) {
        newValue = dropPosition;
      } else if (value === dropPosition) {
        newValue = sourcePosition;
      }

      newPositions.push(newValue);

      if (newValue !== p) {
        done = false;
      }

      p = p + 1;
    }

    this.setState({ positions: newPositions });

    if (done) {
      audioWin.play();
      this.props.onDone();
      this.setState((state) => ({ showConfeti: true }));

      window.plausible("puzzle_complete");
      this.props.setShowScore("success");
      setTimeout(() => {
        //this.props.history.push("/subscribe")
        /* 
        this.props.history.push({
          pathname: "/subscribe",
          state: { game: "puzzle" },
        }); */
      }, 2000);
    }
  }

  renderSquares() {
    const { image, size, level } = this.props;
    const { positions } = this.state;

    const squares = positions.map((i) => {
      return (
        <Cell
          key={i}
          size={this.state.matches ? 600 : 300}
          image={image}
          level={level}
          position={i}
          onSwap={this.onSwap.bind(this)}
        />
      );
    });

    return squares;
  }

  render() {
    const { size, done, showFail } = this.props;

    return (
      <div
        className={"puzzle-container " + this.state.showConfeti}
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: 0,
          width: `${this.state.matches ? 600 : 300}px`,
          height: `${this.state.matches ? 600 : 300}px`,
          margin: "0 auto",
        }}>
        {this.renderSquares()}
      </div>
    );
  }
}

Puzzle.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.number,
  level: PropTypes.number,
  onDone: PropTypes.func,
};

Puzzle.defaultProps = {
  size: 300,
  level: 3,
  onDone: () => {},
};

export default withRouter(DragDropContext(MultiBackend(HTML5toTouch))(Puzzle));
