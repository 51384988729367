import axios from "axios";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems } from "utils/animations";
import { showOptInReducer, optInReducer } from "store/gameSlice";
import { useTranslation } from "react-i18next";

import {
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import Wrapper from "components/Wrapper/Wrapper";
import Footer from "components/Footer/Footer";
import Button from "components/Button/Button";

import { importImages } from "utils/importImages";
const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function OptIn({ step, setStep, currentLanguage }) {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");

  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { t } = useTranslation();

  const updateUser = (e) => {
    const data = {
      quizId: "81ed7d7c-1d3a-jubjupol",
      name: `${firstName + lastName}`,
      email,
      avatar,
      newsletter,
      terms,
    };

    setLoading(true);
    setIsError(false);
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setLoading(false);
    } else if (localStorage.getItem("token") === null) {
      axios
        .post("https://api.escapebox.si/quizbox/user", data)
        .then((res) => {
          localStorage.setItem("token", JSON.stringify(res.data.token));
          dispatch(showOptInReducer(false));
          dispatch(optInReducer(true));
          setLoading(false);
          window.plausible("opt_in");
          if (data.newsletter === true) {
            window.plausible("newsletter");
          }
        })
        .catch(function (error) {
          if (error.response) {
            setValidated(false);
            if (error.response.data.error === "email_already_exists") {
              setErrorMessage("Uporabnik s tem email-om je že sodeloval.");
            }
            setIsError(true);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          setLoading(false);
        });
    } else {
      axios
        .put("https://api.escapebox.si/quizbox/user", data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          dispatch(showOptInReducer(false));
          dispatch(optInReducer(true));
          setLoading(false);
          window.plausible("opt_in");
          if (data.newsletter === true) {
            window.plausible("newsletter");
          }
        })

        .catch(function (error) {
          if (error.response) {
            setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            if (error.response.data.error === "email_already_exists") {
              setErrorMessage("Uporabnik s tem email-om je že sodeloval.");
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          setLoading(false);
        });
    }

    setValidated(true);
  };

  const clickHandler = (optin) => {
    if (optin === "true") {
    } else if (optin === "false" && localStorage.getItem("token") === null) {
      dispatch(optInReducer(false));
      const data = {
        quizId: "81ed7d7c-1d3a-jubjupol",
        guest: true,
      };
      axios
        .post("https://api.escapebox.si/quizbox/user", data)
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("token", JSON.stringify(res.data.token));

          dispatch(showOptInReducer(false));
          console.log(data, res.data);
        })
        .then((res) => {
          console.log(res.data);

          //setIsOpen(false);
          //setLocalStorage();
          //setErrorMessage("");
        })

        .catch(function (error) {
          if (error.response) {
            //setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          }

          //setLoading(false);
        });
    } else if (optin === "guest") {
      //setStep("final");
      dispatch(optInReducer("guest"));
      dispatch(showOptInReducer(false));
      //dispatch(optInReducer(true));
    } else {
      dispatch(showOptInReducer(false));
    }
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="messages d-flex justify-content-center flex-column align-items-center">
      <motion.div
        className="container narrow vh-100 text-center d-flex align-items-center justify-content-center"
        variants={staggerItems}
        initial="hidden"
        animate="show">
        <Wrapper className="optin wrapper__narrow text-center">
          {step === "before" ? (
            <h2
              className="mt-3 pt-4 "
              dangerouslySetInnerHTML={{ __html: t("OptH") }}
            />
          ) : (
            <h2
              className="mt-3 pt-4"
              dangerouslySetInnerHTML={{ __html: t("OptH2") }}
            />
          )}

          <p dangerouslySetInnerHTML={{ __html: t("OptP") }} />

          <Form noValidate validated={validated} onSubmit={updateUser}>
            {currentLanguage === "hr" && (
              <Row className="mb-2">
                <Form.Group controlId="school">
                  <Form.Select
                    required
                    onChange={(event) => {
                      setAvatar(event.target.value);
                    }}
                    value={avatar}
                    aria-label="">
                    <option value="">Izberite vrtec</option>
                    <option value={t("OptKindergarten1")}>
                      {t("OptKindergarten1")}
                    </option>
                    <option value={t("OptKindergarten2")}>
                      {t("OptKindergarten2")}
                    </option>
                    <option value={t("OptKindergarten3")}>
                      {t("OptKindergarten3")}
                    </option>
                    <option value={t("OptKindergarten4")}>
                      {t("OptKindergarten4")}
                    </option>
                    <option value={t("OptKindergarten5")}>
                      {t("OptKindergarten5")}
                    </option>
                    <option value={t("OptKindergarten6")}>
                      {t("OptKindergarten6")}
                    </option>
                  </Form.Select>

                  <Form.Control.Feedback
                    type="invalid"
                    className="text-secondary">
                    Prosimo izberite šolo
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            )}

            <Row className="mb-2">
              <Form.Group as={Col} controlId="Firstname">
                <Form.Control
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder={t("OptFirstname")}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Prosimo, vpišite ime.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} controlId="Lastname">
                <Form.Control
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder={t("OptLastname")}
                />
                <Form.Control.Feedback type="invalid">
                  Prosimo, vpišite priimek
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col} controlId="Email">
                <Form.Control
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={t("OptEmail")}
                  isInvalid={errorMessage && "true"}
                />
                <Form.Control.Feedback type="invalid">
                  {errorMessage
                    ? errorMessage
                    : "Prosimo, vpišite email naslov."}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <div className="row g-2 text-start">
              <div className="col-12">
                <Row className="mt-4">
                  <Form.Group className="mb-1">
                    <Form.Check id="optin" type="checkbox">
                      <Form.Check.Input
                        onChange={(e) => setTerms(e.target.checked)}
                        type="checkbox"
                        required
                      />
                      <Form.Check.Label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t("OptPolicy"),
                          }}
                          className="mb-0"
                        />
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Strinjati se morate s pravili in pogoji.
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-1">
                    <Form.Check id="newsletter" type="checkbox">
                      <Form.Check.Input
                        onChange={(e) => setNewsletter(e.target.checked)}
                        type="checkbox"
                      />
                      <Form.Check.Label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t("OptNewsletter"),
                          }}
                          className="mb-0"
                        />
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Row>
              </div>

              <div className="col-12">
                {isError && (
                  <div style={{ bottom: "0" }} className="position-absolute">
                    <p className="text-center lead text-red fw-bold mb-0">
                      Ta račun ne obstaja.
                    </p>
                  </div>
                )}

                {step === "before" ? (
                  <>
                    <Button
                      label={t("OptStart")}
                      className="btn btn-orange w-100 mb-2"
                    />

                    {currentLanguage !== "hr" && (
                      <a
                        href="#"
                        onClick={() => clickHandler("false")}
                        className="btn btn-outline-lidlblue fs-6 py-2 w-100 text-underline">
                        <u>{t("OptSkip")}</u>
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      label={t("Draw")}
                      className="btn btn-orange w-100 mb-2"
                    />
                    {currentLanguage !== "hr" && (
                      <a
                        href="#"
                        onClick={() => clickHandler("guest")}
                        className="btn btn-sm w-100 mt-1">
                        {t("EndGameGuest")}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </Form>
        </Wrapper>
      </motion.div>
      <Footer />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60px", left: "15%" }}
        src={images["elm-marjetica.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "20%", right: "10%" }}
        src={images["elm-zvezda.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60%", right: "5%" }}
        src={images["elm-marjetica.png"]}
      />

      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "10%", left: "5%" }}
        src={images["elm-skoljka1.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "0%", right: "15%" }}
        src={images["elm-skoljka2.png"]}
      />
    </motion.div>
  );
}

export default OptIn;
