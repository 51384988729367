import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useCountdown from "@bradgarropy/use-countdown";

export const CountDownPreGame = ({ completeCoundown, setCompleteCoundown }) => {
  const countdown = useCountdown({
    seconds: 3,
    onCompleted: () =>
      setTimeout(() => {
        setCompleteCoundown(true);
      }, 1000),
  });

  return (
    <div className="countdowncontainer">
      <AnimatePresence>
        <motion.h1
          key={countdown.seconds}
          exit={{ y: 150, opacity: 0, position: "absolute" }}
          initial={{ y: -150, opacity: 0 }}
          animate={{ y: 0, opacity: 1, scale: 5 }}
          transition={{
            duration: 1,
            type: "spring",
            bounce: 0.45,
          }}>
          {countdown.seconds == 0 ? (
            <span className="text-orange">GO</span>
          ) : (
            countdown.seconds
          )}
        </motion.h1>
      </AnimatePresence>
    </div>
  );
};
